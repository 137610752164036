import React from 'react';

interface HIHLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
}

const HIHLink: React.FC<HIHLinkProps> = (props) => {
  return <a {...props}>{props.children}</a>;
};

export default HIHLink;

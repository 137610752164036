/* 
Utility function to run cleaner tailwind code with dynamic values.

`cn` combines class names using `clsx` and merges them with Tailwind CSS styles using `tailwind-merge`. 
This utility ensures that class names are properly merged and deduplicated, facilitating 
the application of conditional and dynamic class names in a React component.

More information: https://www.youtube.com/watch?v=re2JFITR7TI
*/

import { ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

'use client';

import HIHButton from '@/components/base/Button';
import StopSticker from '@/components/base/StopSticker';
import TripleLines from '@/components/base/TripleLines';

export default function Error({
  // error,
  reset,
}: {
  // error: Error & { digest?: string };
  reset: () => void;
}) {
  // useEffect(() => {
  //   // console.error('*** error.tsx', error);
  // }, [error]);

  return (
    <div className=" flex justify-center items-center flex-col sm:max-w-[532px] m-auto text-center relative min-h-screen px-xl">
      <div className="relative flex justify-center">
        <h2 className="font-display text-display-lg uppercase ">
          {' '}
          <TripleLines variant={'topLeft'}>HIER </TripleLines> LÖPPT IRGENDWAS NICHT
          <StopSticker
            className=" after:!w-full 
          after:bg-[length:57px_57px] after:top-[70px] after:left-[80px]
          lg:after:bg-[length:80px_80px] lg:after:top-[20px] lg:after:left-[200px] 
          "
          >
            {' '}
            &nbsp;RICHTIG
          </StopSticker>
        </h2>
        <div className="block lg:hidden absolute -z-10">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="239"
            height="229"
            viewBox="0 0 239 229"
            fill="none"
            aria-hidden="true"
            focusable="false"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M134.129 2.64958C168.533 3.69279 209.15 -3.55294 229.955 23.868C250.53 50.9874 229.625 87.2093 223.72 120.735C217.101 158.312 229.456 210.367 194.438 225.518C159.441 240.661 132.022 192.52 98.1088 175.083C66.3592 158.759 21.2707 162.069 7.21045 129.254C-8.51896 92.544 2.45323 46.0326 30.7289 17.8269C56.6771 -8.05706 97.4953 1.53874 134.129 2.64958Z"
              fill="#EDEDED"
            />
          </svg>
        </div>
      </div>
      <p className=" pt-5xl text-title-md lg:text-title-lg">
        Entschuldige, hier ist irgendwas schiefgelaufen. Prüfe bitte deine
        Internetverbindung und versuche es noch einmal.
      </p>
      <HIHButton
        onClick={() => reset()}
        title="Nochmal versuchen"
        variant="primary"
        className=" mt-5xl"
      />

      <div className="hidden lg:block absolute -z-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="500"
          height="446"
          viewBox="0 0 500 446"
          fill="none"
          aria-hidden="true"
          focusable="false"
        >
          <path
            d="M195.104 14.219C101.665 38.7838 -78.7962 107.633 39.4287 342.892C110.96 467.671 199.812 448.35 272.855 439.03C338.857 423.113 453.367 412.985 489.478 306.505C504.165 263.267 502.566 201.26 485.029 143.977C446.902 19.6423 348.05 -25.9904 195.104 14.219Z"
            fill="#EDEDED"
          />
        </svg>
      </div>
    </div>
  );
}

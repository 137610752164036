import React, { forwardRef } from 'react';
import { cn } from '@/utils/cn';
import { NewTabHint } from './NewTabHint';
import Image from 'next/image';
import HIHLink from './Link';

interface HIHButtonProps {
  link?: string;
  onClick?: () => void;
  submit?: boolean;
  isLoading?: boolean;
  title: string;
  ariaLabel?: string;
  variant?: 'light' | 'dark' | 'secondary' | 'primary' | 'outline';
  size?: 'sm' | 'md' | 'lg';
  blank?: boolean;
  className?: string;
  showArrowAfter?: boolean;
  showArrowBefore?: boolean;
  iconAfterUrl?: string;
  iconBeforeUrl?: string;
}

const HIHButton = forwardRef<HTMLButtonElement, HIHButtonProps>(
  (
    {
      link,
      onClick,
      submit = false,
      title,
      ariaLabel,
      variant = 'dark',
      blank = false,
      className,
      showArrowAfter = false,
      showArrowBefore = false,
      isLoading = false,
      size = 'lg',
      iconAfterUrl,
      iconBeforeUrl,
    },
    ref,
  ) => {
    const buttonClasses = cn(
      variant === 'secondary'
        ? `
          border-sys/secondary/secondary-fixed bg-sys/secondary/secondary-fixed text-sys/secondary/on-secondary-fixed
          hover:border-sys/secondary/secondary-fixed/80 hover:bg-sys/secondary/secondary-fixed/80
          `
        : variant === 'outline'
          ? `border !border-sys/primary/primary hover:bg-sys/primary/primary/8 `
          : `
            border-bg-sys/primary/primary bg-sys/primary/primary text-sys/primary/on-primary
            hover:border-sys/primary/primary/80 hover:bg-sys/primary/primary/80
            `,
      size === 'sm'
        ? 'py-[10px]'
        : size === 'md'
          ? 'py-[10px] lg:py-[14px]'
          : 'py-[14px] lg:py-xl',
      `
        px-3xl rounded-xs
        flex items-center justify-center
        motion-safe:transition-all
        
        border border-transparent !text-label-lg lg:!text-title-md
        `,
      className,
    );

    function renderLabel() {
      return (
        <div className="flex">
          {showArrowBefore && (
            <div className="pr-md">
              <Image
                src="/icons/arrow_forward.svg"
                width={20}
                height={20}
                alt="Arrow forward"
                className="transform rotate-180"
              />
            </div>
          )}
          {iconBeforeUrl && (
            <Image
              src={iconBeforeUrl}
              width={20}
              height={20}
              className=" pl-md w-[30px] "
              alt="Icon after"
            />
          )}
          {title}
          {iconAfterUrl && (
            <Image
              src={iconAfterUrl}
              width={20}
              height={20}
              className=" pl-md w-[30px]"
              alt="Icon after"
            />
          )}
          {showArrowAfter && (
            <div className="pl-md">
              <Image
                src="/icons/arrow_forward.svg"
                width={20}
                height={20}
                alt="Arrow forward"
              />
            </div>
          )}
        </div>
      );
    }

    if ((onClick || submit) && !link) {
      return (
        <button
          ref={ref}
          type={submit ? 'submit' : 'button'}
          onClick={onClick}
          className={cn(buttonClasses, isLoading && 'cursor-not-allowed')}
          aria-label={ariaLabel || title}
          disabled={isLoading}
        >
          {isLoading ? <>Laden...</> : renderLabel()}
          {iconAfterUrl && (
            <Image
              src={iconAfterUrl}
              width={20}
              height={20}
              className=" pl-md w-[30px]"
              alt="Icon after"
            />
          )}
        </button>
      );
    }

    if (link) {
      return (
        <HIHLink
          href={link}
          target={blank ? '_blank' : undefined}
          rel={blank ? 'noopener noreferrer' : undefined}
          className={buttonClasses}
          aria-label={ariaLabel || title}
        >
          {renderLabel()}
          {blank && <NewTabHint />}
        </HIHLink>
      );
    }

    return null;
  },
);

HIHButton.displayName = 'HIHButton';

export default HIHButton;

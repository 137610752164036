import React, { ReactNode } from 'react';
import styles from '@/styles/modules/TripleLines.module.css';
import { cn } from '@/utils/cn';

interface TripleLinesProps {
  children: ReactNode;
  variant: 'topLeft' | 'topRight' | 'bottomRight';
  isLargeSize?: boolean;
  className?: string;
  isContentFullWidth?: boolean;
}

const TripleLines: React.FC<TripleLinesProps> = ({
  children,
  variant,
  isLargeSize = false,
  className,
  isContentFullWidth: isContentFullWidth = false,
}) => {
  const getVariantStyleClass = () => {
    switch (variant) {
      case 'topLeft':
        return isLargeSize ? styles.topLeftImage : styles.topLeft;
      case 'topRight':
        return styles.topRight;
      case 'bottomRight':
        return isLargeSize ? styles.bottomRightImage : styles.bottomRight;
    }
  };

  const style = getVariantStyleClass();

  return (
    <div
      className={cn(isContentFullWidth && 'w-full', 'relative inline-block lg:w-auto')}
    >
      <span className={cn(style, isContentFullWidth && 'w-full')}>{children}</span>
    </div>
  );
};

export default TripleLines;

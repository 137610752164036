import React, { ReactNode } from 'react';
import styles from '@/styles/modules/StopSticker.module.css';
import { cn } from '@/utils/cn';

interface StopStickerProps {
  children: ReactNode;
  isLargeSize?: boolean;
  className?: string;
}

const StopSticker: React.FC<StopStickerProps> = ({ children, className }) => {
  const getVariantStyleClass = () => {
    return styles.bottomRight;
  };

  const style = getVariantStyleClass();

  return (
    <div className="relative inline-block">
      <span className={cn(style, className)}>{children}</span>
    </div>
  );
};

export default StopSticker;
